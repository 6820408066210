import { action, observable } from 'mobx';
import { isEmpty, reduce } from 'lodash';
import Prefixes from '@dev-togetherprice/constants.prefixes';
import authStore from './auth';
import Err from '../utils/error';
import { WalletParams, WalletStatus } from '../various/enums';
import Store from '../various/store';
import Financial from '../utils/financial';
import t from './translate';
import momentStore from './moment';
import Paging from '../various/paging';
import Ums from '../utils/ums';
import userStore from './user';
import modalStore from './modal';

class WalletStore extends Store {
  @observable balance;

  @observable history;

  @observable interval;

  @observable latam;

  @observable lemonway;

  @observable params;

  @observable savings;

  @observable status;

  @observable hasUserFrozenFunds;

  factory() {
    this.balance = {
      available: {},
      blockedCredit: {},
      total: {},
    };
    this.history = new Paging(() => this.getHistory());
    this.interval = {
      from: momentStore.e(Date.now()).subtract(10, 'year'),
      to: momentStore.e(Date.now()),
    };
    this.latam = {
      country: '',
    };
    this.lemonway = false;
    this.params = {
      type: WalletParams.TYPE.ALL,
      subType: WalletParams.SUBTYPE.NONE,
      status: WalletParams.STATUS.NONE,
    };
    this.savings = {
      currency: '',
      value: 0,
    };
    this.status = {
      balance: WalletStatus.BALANCE.PENDING,
    };
    this.hasUserFrozenFunds = false;
  }

  /**
   * Gets the logged user wallet balance
   *
   * @returns {boolean}
   */
  async getBalance() {
    let wallet;

    this.set('status.balance', WalletStatus.BALANCE.PENDING);

    // Get the user wallet through the server
    wallet = await Financial.get('wallet/getWalletBalance', authStore.token);
    if (Err.check(wallet)) return this.set('status.balance', WalletStatus.BALANCE.ERROR);

    wallet = {
      available: wallet.availableBalance,
      blockedCredit: wallet.blockedCreditBalance,
      total: wallet.totalBalance,
    };

    // Save in separate variables the available balance, the withdrawable balance and the totalBalance
    this.set('balance', wallet);

    // If the code arrived here then it is a success
    this.set('status.balance', WalletStatus.BALANCE.SUCCESS);

    return true;
  }

  async getHasUserFrozenFunds() {
    const response = await Financial.get('/hasUserFrozenFunds', authStore.token, null);
    if (Err.check(response)) return response;
    return this.set('hasUserFrozenFunds', response);
  }

  /**
   * Gets the payment history of the logged user
   *
   * @returns {boolean}
   */
  async getHistory() {
    let params; let
      history;

    // Declare get parameters
    params = {
      type:
        this.params.type !== WalletParams.TYPE.ALL
          ? `type=${this.params.type === WalletParams.TYPE.REFUND ? WalletParams.TYPE.PAYMENT : this.params.type}&`
          : '',
      subType: this.params.subType !== WalletParams.SUBTYPE.NONE ? `subType=${this.params.subType}&` : '',
      status: this.params.status !== WalletParams.STATUS.NONE ? `status=${this.params.status}&` : '',
      from: this.interval.from.valueOf(),
      to: momentStore
        .e(this.interval.to)
        .add(1, 'day')
        .valueOf(),
      direction: 'DESC',
      props: 'createdAt',
    };

    // Get the user payment history from the server
    history = await Financial.get(
      `wallet/getWalletHistory?${params.type}${params.subType}${params.status}fromDate=${params.from}&toDate=${params.to}&page=${this.history.current}&direction=${params.direction}&props=${params.props}`,
      authStore.token,
    );

    return history;
  }

  async getSavings() {
    let savings;

    savings = await Financial.get('getSavings', authStore.token);
    if (Err.check(savings)) return false;

    this.set('savings', savings);

    return true;
  }

  @action resetHistory() {
    this.history = new Paging(() => this.getHistory());
    this.interval = {
      from: momentStore.e(Date.now()).subtract(10, 'year'),
      to: momentStore.e(Date.now()),
    };
    this.params = {
      type: WalletParams.TYPE.ALL,
      subType: WalletParams.SUBTYPE.NONE,
      status: WalletParams.STATUS.NONE,
    };
    this.status.history = WalletStatus.HISTORY.PENDING;
  }

  update(subType = WalletParams.SUBTYPE.NONE, status = WalletParams.STATUS.NONE) {
    this.set('params.subType', subType);
    this.set('params.status', status);
    this.history.getFirstPage();
  }

  get availableBalance() {
    return this.balance.available;
  }

  get blockedCreditBalance() {
    return this.balance.blockedCredit;
  }

  get callbacks() {
    return {
      ALL: () => this.update(),
      PAYMENT: () => this.update(WalletParams.SUBTYPE.RECEIVED),
      PROMO: () => this.update(WalletParams.SUBTYPE.NONE, WalletParams.STATUS.ACTIVE),
      WITHDRAW: () => this.update(),
      REFUND: () => this.update(WalletParams.SUBTYPE.NONE, WalletParams.STATUS.REFUND_PENDING),
    };
  }

  onAskRefund = async (e) => {
    const response = await Ums.get('/canCollectCellphoneBeforeRefund', authStore.token); // TODO migrate to configurations
    if (!userStore.hasCellphone && response) {
      modalStore.success('postMore');
      authStore.set('cellphonePrefix', Array.from(Prefixes).filter((prefix) => prefix.countryCode === userStore.user.country)[0].value);
      modalStore.pending('verifyPhone', null, { isRefund: true, onNext: e });
    } else e();
  };

  get hasMoney() {
    return this.balance.total.value > 0;
  }

  get isHistoryEmpty() {
    return isEmpty(this.history);
  }

  get totalBalance() {
    return this.balance.total;
  }

  get types() {
    return reduce(
      Object.values(t.s.walletTypes),
      (r, v, k) => [...r, ...[{ ...v, callback: this.callbacks[v.output] }]],
      [],
    );
  }
}

const walletStore = new WalletStore();
export default walletStore;
