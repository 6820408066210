import { camelCase, isEmpty } from 'lodash';
import { action, observable } from 'mobx';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import gift from '../assets/credit-card-modal/gift.svg';
import Apple from '../utils/apple';
import Err, { getErrorMessageWithoutServerPrefix } from '../utils/error';
import Facebook from '../utils/facebook';
import Financial from '../utils/financial';
import Google from '../utils/google';
import { dispatchPixel } from '../utils/pixel';
import Retention from '../utils/retention';
import Server from '../utils/server';
import Ums from '../utils/ums';
import { AuthStatus } from '../various/enums';
import Store from '../various/store';
import captchaStore from './captcha';
import appStore from './app';
import modalStore from './modal';
import momentStore from './moment';
import navigatorStore from './navigator';
import settingStore from './setting';
import stripeStore from './stripe';
import toastStore from './toast';
import trackingStore from './tracking';
import t from './translate';
import userStore from './user';

const sleep = () => new Promise((resolve) => setTimeout(resolve, 1000));
const repeatUntilTrue = async (fn, limit = 10) =>
    limit &&
    Promise.resolve(fn()).then((response) =>
        response === false ? sleep().then(() => repeatUntilTrue(fn, limit - 1)) : response
    );

class AuthStore extends Store {
    @observable autoPromo;

    @observable birthday;

    @observable denyWhatsapp;

    @observable cellphone;

    @observable cellphoneCode;

    @observable ibanToken;

    @observable ibanCode;

    @observable cellphonePrefix;

    @observable confirmPassword;

    @observable countryResidence;

    @observable currentPassword;

    @observable email;

    @observable name;

    @observable newsletter;

    @observable password;

    @observable policy;

    @observable status;

    @observable surname;

    @observable token;

    @observable promoCode;

    @observable attemps;

    @observable countries;

    factory() {
        this.ibanToken = '';
        this.email = '';
        this.password = '';
        this.confirmPassword = '';
        this.currentPassword = '';
        this.name = '';
        this.surname = '';
        this.policy = false;
        this.newsletter = false;
        this.token = '';
        this.cellphone = '';
        this.cellphoneCode = '';
        this.ibanCode = '';
        this.cellphonePrefix = '';
        this.birthday = '';
        this.countryResidence = '';
        this.promoCode = '';
        this.autoPromo = {
            active: false,
            details: {},
        };
        this.status = AuthStatus.PENDING;
        this.denyWhatsapp = false;
        this.attemps = 0;
        this.countries = [];
    }

    /**
     * Checks if the access token has been saved inside the storage
     */
    async init() {
        // Get the user token from the storage
        const storage = await this.fromStorage();
        if (!storage) return this.set('status', AuthStatus.ERROR);

        // If the token does not exist then you have to login/signup
        this.set('status', AuthStatus.SUCCESS);
    }

    // //////////// NEW LOGIN/SIGNUP WORKFLOW V2 //////////////////

    getEndpointWithParams(endpoint, optionalParams) {
        if (optionalParams) {
            return `${endpoint}?${Object.entries(optionalParams)
                .filter(([, value]) => !!value)
                .map((entry) => entry.join('='))
                .join('&')}`;
        }
        return endpoint;
    }

    async v2Signup(payload, reCaptchaToken, optionalParams = null) {
        const response = await Ums.post(
            this.getEndpointWithParams('/user/v2/create', optionalParams),
            payload,
            undefined,
            {
                'Captcha-Token': reCaptchaToken,
            }
        );
        if (optionalParams.postReferrer) appStore.clearLinkParams();
        if (Err.check(response)) return response;
        dispatchPixel('button-click-complete-sign-up');
        this.set('token', response.token);
        return response;
    }

    async v2Login(payload, reCaptchaToken, optionalParams = null) {
        const response = await Ums.post(this.getEndpointWithParams('auth', optionalParams), payload, undefined, {
            'Captcha-Token': reCaptchaToken,
        });
        if (optionalParams.postReferrer) appStore.clearLinkParams();
        if (Err.check(response)) return response;
        return response;
    }

    async v2PrepareGoogle() {
        const google = await Google.auth();
        if (Err.check(google)) return null;
        if (!google?.credentials?.accessToken) return null;
        return {
            socialAccessToken: google.credentials.accessToken,
            name: google.user.givenName,
            surname: google.user.familyName,
            email: google.user.email,
            lang: t.l,
        };
    }

    /**
     * @typedef FacebookProfile
     * @property {string} first_name
     * @property {string} last_name
     * @property {string} email
     *
     * @param {string} token
     * @returns {Promise<FacebookProfile>}
     */
    fetchFacebookProfile = async (token) => {
        if (!token) return null;
        const recaptcha = await captchaStore.getRecaptcha(captchaStore.isEnabled);
        if (recaptcha.status === 'EXPIRED') return null;

        const response = await Ums.post(
            '/user',
            {
                provider: 'FACEBOOK',
                token,
            },
            undefined,
            {
                'captcha-token': recaptcha.token,
            }
        );

        if (Err.check(response)) return null;

        return response;
    };

    v2PrepareFacebook = async () => {
        try {
            const fbResp = await Facebook.login();
            console.log({ message: '[v2PrepareFacebook]=====FB Resp=====', fbResp });
            const { accessToken } = await Facebook.getCurrentAccessToken();

            if (!accessToken) return null;

            const profile = await this.fetchFacebookProfile(accessToken.token);

            if (!profile) return null;

            return {
                socialAccessToken: accessToken.token,
                name: profile.first_name,
                surname: profile.last_name,
                email: profile.email,
                lang: t.l,
            };
        } catch (e) {
            console.error(e);

            return null;
        }
    };

    async v2PrepareApple() {
        try {
            console.log('start apple login');
            const { response } = await Apple.login();
            console.log('apple login response', response);
            const { identityToken: accessToken } = response;
            if (!accessToken) return null;
            return {
                socialAccessToken: accessToken,
                name: response.givenName,
                surname: response.familyName,
                email: response.email,
                lang: t.l,
            };
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async v2SocialLogin(payload, reCaptchaToken, optionalParams = null) {
        const response = await Ums.post(
            this.getEndpointWithParams('/user/v2/authorize', optionalParams),
            payload,
            undefined,
            {
                'Captcha-Token': reCaptchaToken,
            }
        );
        if (optionalParams.postReferrer) appStore.clearLinkParams();
        if (Err.check(response)) return response;
        return response;
    }

    async isRegistrationCompleted() {
        const response = await repeatUntilTrue(() => Ums.get('user/isRegistrationCompleted', this.token));
        if (Err.check(response)) return response;

        await Promise.all([stripeStore.getStripeInfo(), userStore.init(), userStore.loadUserLanguage()]);

        navigatorStore.unblock();
    }

    async v2GetActiveCountries() {
        const countries = await settingStore.v2GetActiveCountries();
        return this.set(
            'countries',
            Object.values(countries).map((country) => ({
                label: country?.name,
                value: country?.code,
            }))
        );
    }

    /**
     *
     * @returns {boolean}
     */
    async v2RecoverAccount(email) {
        const response = await Ums.post('sendPasswordResetEmail', email, null, {
            'Content-Type': 'application/json',
        });

        if (Err.check(response)) {
            const message = getErrorMessageWithoutServerPrefix(response);
            const isErrorCode = message && !message.includes(' ');
            toastStore.error(`PasswordModal.${isErrorCode ? message : 'update'}`);
            throw new Error(response);
        }
        return true;
    }

    // ///////////////////////////////////////////////////////////

    /**
     * Tries to login with email and password
     *
     * @returns {object}
     */
    async emailLogin() {
        let payload;
        let response;

        // Prepare the payload with email and password
        payload = {
            email: this.email,
            password: this.password,
        };

        // Try to login through the server
        response = await Ums.post('auth', payload);
        if (Err.check(response)) return response;

        return response;
    }

    /**
     * Tries to login with the google server endpoint
     *
     * @returns {object}
     */
    async googleLogin() {
        let payload;
        let response;

        // Get the payload from the prepareGoogle function
        payload = await this.prepareGoogle();
        if (Err.check(payload)) return payload;

        // Try to login through the server
        response = await Ums.post('user/signUpGoogleUser', payload);
        if (Err.check(response)) return response;

        return response;
    }

    async prepareGoogle() {
        const google = await Google.auth();
        if (Err.check(google)) return google;

        return {
            socialAccessToken: google.credentials.accessToken,
            email: google.user.email,
            lang: t.l,
            ...trackingStore.tracking,
        };
    }

    async prepareGoogleContacts() {
        const google = await Google.authContacts();
        if (Err.check(google)) return google;

        return {
            socialAccessToken: google.credentials.accessToken,
            email: google.user.email,
            lang: t.l,
            ...trackingStore.tracking,
        };
    }

    async appleLogin() {
        const payload = await Apple.login();
        if (Err.check(payload)) return payload;
        console.log(payload);
        return Err.create();
    }

    get defaultCountries() {
        return this.countries;
    }

    /**
     * Tries to login with the facebook payload previously saved inside this store
     *
     * @returns {object}
     */
    async facebookLogin() {
        let payload;
        let response;

        // Get the payload from the prepareFacebook function
        payload = await this.prepareFacebook();
        if (Err.check(payload)) return payload;

        // Try to login through the server
        response = await Ums.post('user/signUpFacebookUser', payload);
        if (Err.check(response)) return response;

        return response;
    }

    async prepareFacebook() {
        const facebook = await Facebook.login();
        if (Err.check(facebook)) return facebook;
        return {
            socialAccessToken: facebook.accessToken.token,
            lang: t.l,
            ...trackingStore.tracking,
        };
    }

    /**
     * Tries to register the user to the platform with the data present inside the store
     *
     * @returns {object}
     */
    async signUp() {
        let response;
        let payload;

        // Prepare the payload with the current data in the store
        payload = {
            name: this.name,
            surname: this.surname,
            email: this.email,
            password: this.confirmPassword,
            policy: this.policy,
            newsletter: this.newsletter,
            lang: t.l,
            ...trackingStore.tracking,
        };

        // todo: add signiup analytics

        // Try to sign up through the server
        response = await Ums.post('user/signUp', payload);
        if (Err.check(response)) return response;

        return response;
    }

    async getSignUpStatus() {
        let response;

        response = await Ums.get('user/isRegistrationCompleted', authStore.token);
        if (Err.check(response)) return false;

        return response;
    }

    /** @deprecated * */
    async completeSignUp() {
        let response;

        response = await Ums.post(
            'user/completeRegistration',
            {
                country3: this.countryResidence,
                birthday: momentStore.e(this.birthday).format('YYYY-MM-DD'),
                promoCode: isEmpty(this.promoCode) ? null : this.promoCode,
            },
            this.token
        );
        const alreadyCompleted = response?.__e__?.message?.trim() === 'Server.Complete registration already done!';
        if (!alreadyCompleted && (Err.check(response) || !response)) return response;

        if (!alreadyCompleted) {
            response = await repeatUntilTrue(() => Ums.get('user/isRegistrationCompleted', this.token));
            if (Err.check(response)) return response;
        }

        await Promise.all([stripeStore.getStripeInfo(), userStore.init(), userStore.loadUserLanguage()]);
        if (trackingStore.hasReferral) {
            appStore.openPromoModal().then(() => {
                userStore.setPending();
                return userStore.initData(true).then(() => userStore.setSuccess());
            });
        }

        navigatorStore.unblock();
        navigatorStore.stack('/trigger-redirect');

        return response;
    }

    async registerMissingInfo({ birthday = null, country = null, state = null, city = null, promoCode = null }) {
        let response;
        response = await Ums.post(
            'user/completeRegistration',
            {
                country3: country,
                state,
                city,
                birthday,
                promoCode: isEmpty(promoCode) ? null : promoCode,
            },
            this.token
        );
        const alreadyCompleted = response?.__e__?.message?.trim() === 'Server.Complete registration already done!';
        if (!alreadyCompleted && (Err.check(response) || !response)) return response;

        if (!alreadyCompleted) {
            response = await repeatUntilTrue(() => Ums.get('user/isRegistrationCompleted', this.token));
            if (Err.check(response)) return response;
        }

        await Promise.all([stripeStore.getStripeInfo(), userStore.init(), userStore.loadUserLanguage()]);
        if (trackingStore.hasReferral) {
            appStore.openPromoModal().then(() => {
                userStore.setPending();
                return userStore.initData(true).then(() => userStore.setSuccess());
            });
        }

        navigatorStore.unblock();
        navigatorStore.stack('/trigger-redirect');

        return response;
    }

    /**
     * Sends a link to the given email which will let the user reset its password
     *
     * @param {string} email
     */
    async recoverAccount(email) {
        // Try to recover the password through the server
        const response = await Ums.post('sendPasswordResetEmail', this.email, null, {
            'Content-Type': 'application/json',
        });
        if (Err.check(response)) return false;
        return true;
    }

    /**
     * Sends to the user an email containing a link which will let him verify his account
     *
     * @returns {boolean}
     */
    async resendVerificationEmail() {
        const newSent = await Ums.post('/resendVerificationEmail', {}, authStore.token);
        if (Err.check(newSent)) return false;

        return true;
    }

    /**
     * Verifies the user account through its email and a verification token
     *
     * @async
     * @param {string} email
     * @param {string} verificationToken
     * @returns {Promise<boolean>}
     */
    async verifyEmail(email, verificationToken) {
        await new Promise((resolve) => {
            setInterval(() => this.status !== AuthStatus.PENDING && resolve(), 100);
        });
        const verified = await Ums.post(
            'user/verifyEmail',
            {
                email,
                verificationToken,
            },
            this.token
        );
        if (Err.check(verified)) return false;
        window.localStorage.setItem('VerifyEmail', true);
        return true;
    }

    /**
     * @typedef {Object} VerifyPhoneResponse
     * @property {boolean} success - The status
     * @property {boolean} [mailNotVerified] - If the error is caused by the email not beign verified
     */

    /**
     * Sends an sms to the given cellphone containing a 4 digit code
     *
     * @returns {Promise<VerifyPhoneResponse>}
     */
    async verifyPhone() {
        const response = await Ums.post(
            'sendCellphoneVerificationCode',
            {
                cellphone: this.cellphonePrefix + this.cellphone,
                whatsappPermission: !this.denyWhatsapp,
            },
            authStore.token
        );

        if (!Err.check(response)) {
            return { success: true };
        }

        const message = getErrorMessageWithoutServerPrefix(response);

        if (message === 'USER_EMAIL_NOT_VERIFIED_YET') {
            toastStore.error('VerifyPhoneModal.emailNotVerified');
            return { mailNotVerified: true };
        }
        toastStore.error(`VerifyPhoneModal.${camelCase(message)}`);
        return { success: false };
    }

    /**
     * Verifies the cellphone number through the code previously given
     *
     * @returns {boolean}
     */
    async verifyPhoneCode() {
        if (this.cellphoneCode.length < 4) return false;
        const response = await Ums.post(
            'checkCellphoneVerificationCode',
            {
                cellphone: this.cellphonePrefix + this.cellphone,
                code: this.cellphoneCode,
            },
            authStore.token
        );
        if (Err.check(response)) {
            return toastStore.error(`VerifyPhoneModal.${getErrorMessageWithoutServerPrefix(response)}`);
        }
        userStore.set('user.cellphone', this.cellphone);
        return toastStore.success('VerifyPhoneModal.verifyCode');
    }

    /**
     * Verifies the iban number through the code previously given
     *
     * @returns {boolean}
     */
    async verifyIbanCode() {
        if (this.ibanCode.length < 5) return false;
        const response = await Financial.post('v2/validateSaveBankAccount', this.ibanCode, authStore.token, {
            'content-type': 'text/plain',
        });
        if (Err.check(response))
            return toastStore.error(`VerifyPhoneModal.${getErrorMessageWithoutServerPrefix(response)}`);
        modalStore.success('verifyIban');
        return toastStore.success('VerifyIBANModal.verifyCode');
    }

    /**
     * Updates the user password
     *
     * @returns {boolean}
     */
    async updatePassword() {
        let payload;
        let response;

        payload = {
            old_password: this.currentPassword,
            password: this.password,
            password_confirmation: this.confirmPassword,
            _method: 'PUT',
        };

        response = await Server.post('users/update_password', payload, authStore.token);
        if (Err.check(response)) return false;

        return true;
    }

    /**
     * Resets the user password through email and token obtained through an email link
     *
     * @param {string} email
     * @param {string} token
     * @returns {boolean}
     */
    async resetPassword(email, token) {
        let payload;
        let response;

        payload = {
            email,
            password: this.password,
            password_confirmation: this.confirmPassword,
            token,
        };

        response = await Ums.post('resetPassword', payload);
        if (Err.check(response)) return false;

        return true;
    }

    /**
     * Resets the user password through email and token obtained through an email link
     * @returns {Promise}
     */
    async resetPassword2({ email, password, token }) {
        const payload = {
            email,
            password,
            password_confirmation: password,
            token,
        };
        const response = await Ums.post('resetPassword', payload);

        if (Err.check(response)) {
            const message = getErrorMessageWithoutServerPrefix(response);
            const isErrorCode = !message || !message.includes(' ');
            toastStore.error(`ResetPassword.${isErrorCode ? message : 'reset'}`);
            throw new Error(response);
        }

        return true;
    }

    /**
     * Fetches the auto promo status and details for the current user
     *
     * @returns {boolean}
     */
    async getAutoPromo() {
        let promo;
        let params;

        promo = await Retention.get('promo/get_user_promo?promo_tag=auto_promo', authStore.token);
        if (Err.check(promo) || isEmpty(promo) || !promo) return false;

        params = {
            title: 'CreditCardModal.title.autopromo',
            description: 'CreditCardModal.description.autopromo',
            image: gift,
            ...promo.details,
            renewDay: momentStore.e(promo.details.renew).format('DD MMMM YYYY'),
        };

        if (promo.active) modalStore.set('creditCard', 'pending', null, '', params);

        return true;
    }

    async fromStorage() {
        // Fetch all data saved inside the storage at the AuthStore position
        try {
            const sKeys = await SecureStoragePlugin.keys();
            if (!sKeys.value.includes('AuthStore')) {
                return false;
            }
            const rawData = await SecureStoragePlugin.get({
                key: 'AuthStore',
            });

            const data = JSON.parse(rawData.value);

            Object.entries(data).forEach(([key, value]) => this.set(key, value));
            return true;
        } catch (e) {
            console.error(`[AuthStore] Error on retrieving the key: ${e}`);
            return false;
        }
    }

    /**
     * Saves the given data to the storage
     *
     * @returns {boolean}
     */
    async toStorage() {
        return SecureStoragePlugin.set({
            key: 'AuthStore',
            value: JSON.stringify({
                token: this.token,
            }),
        });
    }

    @action reset() {
        this.factory();
        SecureStoragePlugin.remove({
            key: 'AuthStore',
        });
    }
}

const authStore = new AuthStore();
export default authStore;
