import { Device } from '@capacitor/device';
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
// eslint-disable-next-line import/no-cycle

const loadScript = () => {
  // Load the SDK asynchronously
  (function (d, s, id) {
    let js; const
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
};

const initPromise = Device.getInfo().then((info) => {
  if (info.platform === 'web') {
    loadScript();
    // @ts-ignore
    window.fbAsyncInit = () => {
      // @ts-ignore
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK,
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: 'v5.0', // use graph api current version
      });
    };
  }
});

const facebookConfig = {
  appId: process.env.REACT_APP_FACEBOOK,
  scope: 'email,public_profile,user_friends',
  fields: 'id,email,friends,gender,first_name,last_name,picture',
};

function init() {
  return initPromise;
}

const login = async (): Promise<FacebookLoginResponse | any> => FacebookLogin.login({ permissions: facebookConfig.scope.split(',') });

const getCurrentAccessToken = async () => FacebookLogin.getCurrentAccessToken();

type FacebookLoginProfile = {
  id: string,
  email: string,
  // eslint-disable-next-line camelcase
  first_name: string,
  // eslint-disable-next-line camelcase
  last_name: string,
};
const getProfile = async () => FacebookLogin.getProfile<FacebookLoginProfile>({
  fields: facebookConfig.fields.split(','),
});

const Facebook = {
  init,
  login,
  getCurrentAccessToken,
  getProfile,
};
export default Facebook;
