import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useEffect } from 'react';
import appStore from 'src/shared/stores/app';
import {
  ACCEPTED_NOTIFICATION_TYPES,
  handleRequest,
  Notification,
  RequestAction,
} from 'src/shared/utils/onesignal/notificationHandler';
import { useSnackbar } from '@dev-togetherprice/components.snackbar-provider';
import { useTranslation } from 'react-i18next';
import navigatorStore from 'src/shared/stores/navigator';
import { RequestNotifications, RequestType } from 'src/shared/constants/feed';

export type OneSignalNotificationProps = {
  notification: Notification;
};

const OneSignalNotification: FC<OneSignalNotificationProps> = ({
  notification,
}) => {
  const { setSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onRequest = useCallback(
    (action: RequestAction) => {
      closeSnackbar();
      handleRequest(action, notification);
    },
    [notification]
  );

  useEffect(() => {
    // show snakbar only if type is accepted one
    if (notification && notification.text) {
      setSnackbar({
        message: <Typography variant='body2'>{notification.text}</Typography>,
        onClick: () => {
          navigatorStore.stack(
            `/feed/${
              RequestNotifications.includes(
                notification.notificationType as RequestType
              )
                ? 'requests'
                : 'notifications'
            }`
          );
          closeSnackbar();
        },
        variant: 'default',
        ...(ACCEPTED_NOTIFICATION_TYPES.includes(
          notification.notificationType as RequestType
        ) && {
          actions: [
            { label: t('Request.accept'), onClick: () => onRequest('accept') },
            { label: t('Request.refuse'), onClick: () => onRequest('decline') },
          ],
        }),
      });
    }
  }, [notification]);

  return <></>;
};

export default observer(() => (
  <OneSignalNotification notification={appStore.onesignalNotification} />
));
