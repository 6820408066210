import React, { Suspense, useEffect } from 'react';
import { Route, RouteProps, useRouteMatch } from 'react-router-dom';
import AppLinearProgress from '@dev-togetherprice/components.app-linear-progress';
import modalStore from '../stores/modal';
import { dispatchPixel } from '../utils/pixel';
import Feedback from '../utils/feedback';
import authStore from '../stores/auth';
import userStore from '../stores/user';
import ServerError from './ServerError';

type DispatchPixelProps = {
  name: string;
  data: string;
};

const DispatchPixel: React.FC<DispatchPixelProps> = ({
  name: pixelName,
  data: pixelData,
}) => {
  useEffect(() => {
    if (pixelName) {
      dispatchPixel(pixelName, pixelData);
    }
  }, [pixelName]);
  return <></>;
};

type ErrorBoundaryProps = React.PropsWithChildren<{
  fallback: React.ReactElement;
}>;

type ErrorBoundaryState = {
  hasError: boolean;
  error: any;
};

type Log = {
  userId?: string | number;
  message?: string;
  stackTrace?: string;
  source?: string;
  path?: string;
  userAgent?: string;
  raw?: string;
};
const logError = (log: Log) =>
  Feedback.post('/client/saveLog', log, authStore.token);
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    logError({
      userId: userStore.user?.id,
      raw: JSON.stringify(error),
      message: error?.message,
      stackTrace: error?.stack,
      source: 'error-boundary',
      path: window.location.href,
      userAgent: navigator.userAgent,
    });
    return {
      hasError: true,
      error,
    };
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;
    if (hasError) {
      return fallback;
    }
    return children;
  }
}

const RouteError: React.FC = () => {
  useEffect(() => {
    modalStore.pending('version');
  });

  return <ServerError />;
};

type AsyncRouteProps = RouteProps & {
  pixelName?: string;
  pixelData?: any;
};

const LazyRoute: React.FC<AsyncRouteProps> = ({
  pixelName,
  pixelData,
  path,
  component: Component,
  ...others
}) => {
  const matched = useRouteMatch(path);

  return (
    <ErrorBoundary fallback={<RouteError />}>
      <Route
        {...others}
        path={path}
        render={(props) => (
          <Suspense fallback={<AppLinearProgress />}>
            <DispatchPixel
              name={pixelName}
              data={{ ...(pixelData || {}), ...(matched?.params || {}) }}
            />
            <Component {...props} />
          </Suspense>
        )}
      />
    </ErrorBoundary>
  );
};

export default LazyRoute;
