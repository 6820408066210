import { getStripeTranslations } from '@dev-togetherprice/hooks.use-stripe-translation';
import merge from 'lodash/merge';

const mergeAsync = (promises) =>
  Promise.all(promises).then((data) => {
    if (data.default) {
      return merge(...data.default);
    }
    return merge(...data);
  });

const importStripeTranslation = (lang) =>
  getStripeTranslations(lang).then((data) => ({
    Error: { Stripe: { ...data } },
  }));

const importEnglish = () =>
  mergeAsync([
    import(/* webpackChunkName: 'app.i18n.en' */ './english.json'),
    import(/* webpackChunkName: 'dropdown.i18n.en' */ './dropdownEnglish.json'),
    importStripeTranslation('en'),
  ]);
const importItalian = () =>
  mergeAsync([
    import(/* webpackChunkName: 'app.i18n.it' */ './italian.json'),
    import(/* webpackChunkName: 'dropdown.i18n.it' */ './dropdownItalian.json'),
    importStripeTranslation('it'),
  ]);
const importSpanish = () =>
  mergeAsync([
    import(/* webpackChunkName: 'app.i18n.es' */ './spanish.json'),
    import(/* webpackChunkName: 'dropdown.i18n.es' */ './dropdownSpanish.json'),
    importStripeTranslation('es'),
  ]);

const importPortuguese = () =>
  mergeAsync([
    import(/* webpackChunkName: 'app.i18n.pt' */ './portuguese.json'),
    import(
      /* webpackChunkName: 'dropdown.i18n.pt' */ './dropdownPortuguese.json'
    ),
    importStripeTranslation('pt'),
  ]);

const getTranslations = (locale) => {
  switch (locale) {
    case 'it':
      return importItalian();
    case 'es':
    case 'mx':
    case 'co':
    case 'pe':
    case 'ar':
    case 'ec':
    case 'cl':
    case 'ca':
      return importSpanish();
    case 'pt':
      return importPortuguese();
    case 'en':
      return importEnglish();
    default:
      return importEnglish();
  }
};
export default getTranslations;
export async function* translationsGenerator() {
  let language = 'en';
  do {
    language = yield await getTranslations(language);
  } while (true);
}
